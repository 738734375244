import React from "react";
import UploadForm from "../components/UploadForm";

export default function Upload() {
  return (
    <>
      <div className="innercontent">
        <UploadForm />
      </div>
    </>
  );
}
