import React from "react";
import { Container } from "react-bootstrap";

function Cart() {
  return (
    <>
      <Container>
        <div className="heading">
          <h1>Cart</h1>
        </div>
      </Container>
    </>
  );
}

export default Cart;
